body {
  margin: 0;
  font-family: Open-Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chapContentBox {
  padding-top: 25px;
}

.chapBtnBox {
  text-align: center;
}

.prevChapBtn, .nextChapBtn {
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 5px;
  margin-bottom: 20px;
}

p {
  font-size: 20px !important;
}

.calibre {
  padding-left: 15%;
  padding-right: 15%;
}

.calibre2 {
  font-size: 30px;
}

.bold-text {
  font-weight: 600 !important;
}

.normal-text {
  font-weight: 500;
}

p.bold-text em {
  font-weight: 600 !important;
}

p.bold-text em span {
  font-weight: 600 !important;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('/public/assets/images/dangobanner.jpg'); /* Use the URL with correct path */
  background-size: cover; /* Adjust as needed to control how the image covers the container */
  background-repeat: no-repeat; /* Prevent repeating the image */
}


.loginDiv {
  text-align: center;
  margin-top: -20%;
}

.loginDiv h1 {
  font-size: 74px;
  margin-bottom: 10%;
  color: #ff2600; /* Pastel color */
  font-weight: 600; /* Font weight */
  font-family: 'Lobster', cursive; /* Use the Lobster font with cursive fallback */
}

.loginDiv input, .loginDiv button {
  display: block;
  margin: 10px auto;
}

.loginDiv input {
  width: 50%;
}
